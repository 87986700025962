import React, {
  useEffect,
  lazy,
  useState,
  Suspense,
  useCallback,
  useMemo,
} from 'react'
import { Switch, Redirect } from 'react-router-dom'
import { useHistory, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Container from './Container'
import Axios, { AxiosError } from 'axios'
import { message } from 'antd'
import BindDevice from '../views/bind-device'
import { getAccountInfo, AxiosInitialInstance } from '../utils/api'
import { getPayInfo } from '../api/user'
import { AxiosCostomInstance } from '../utils/services'
import { useDispatch } from 'react-redux'
import { logout } from '../redux/user/actions'
import { AppState } from '../redux'
import * as errcodes from '../constants/errCodes'
import { UserInfo } from '../redux/user/types'
import { getWithdrawParams } from '@/api/meta'
import { Route } from 'react-router-dom'
import { login } from '@/redux/user/actions'
const DeviceList = lazy(() => import('../views/device-list'))
// import DeviceAdd from './DeviceAdd';
const BillDashboard = lazy(() => import('../views/dashboard/BillDashboard'))
const TechDashboard = lazy(() => import('../views/dashboard/TechDashboard'))
const PCenter = lazy(() => import('../views/pcenter'))
const Wallet = lazy(() => import('../views/wallet'))
const MinerList = lazy(() => import('../views/miner/MinerList'))
const OpenplatformAuth = lazy(() => import('../views/openplatform/auth'))
const OpenplatformRedirectUri = lazy(
  () => import('../views/openplatform/auth/redirect'),
)
const SelfTickets = lazy(() => import('../views/tickets/index'))
const Mobile = lazy(() => import('../mobile/route/container'))
const DialList = lazy(() => import('../views/dial'))
const DialConfig = lazy(() => import('../views/dial/config'))
const Deploy = lazy(() => import('../views/deploy'))
const ScanDevice = lazy(() => import('../components/ScanDevice'))
/*
 
 本页会处理需求需要登录但是没登录的情况
 如果登录的是不是需要登录的页面，就不会走到相关逻辑
 否则就会调用相关接口，判断是不是401，如果是则携带者当前路径去登录页面。以便登录后返回
 
 */
function getTokenFromUrl(location: { search: string }) {
  const searchParams = new URLSearchParams(location.search)
  return searchParams.get('token')
}
const App = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const location = useLocation()

  const [isGettingAccountinfo, setIsGettingAccountinfo] = useState(false)
  const SearchToken = useMemo(() => {
    return getTokenFromUrl(location)
  }, [location])
  const { token } = useSelector<AppState, UserInfo>((state) => state.user)
  useEffect(() => {
    if (SearchToken) {
      dispatch(login(SearchToken))
    }
  }, [SearchToken, dispatch])

  const goLogin = useCallback(() => {
    dispatch(logout())
    history.replace(
      '/auth?pathname=' +
        encodeURIComponent(location.pathname) +
        '&search=' +
        encodeURIComponent(location.search),
    )
  }, [dispatch, history, location.pathname, location.search])

  useEffect(() => {
    const initialId = AxiosInitialInstance.interceptors.response.use(
      (response) => {
        return response
      },
      (error: AxiosError) => {
        if (error?.response?.status === 401) {
          goLogin()
        } else if (error?.response?.status === 400) {
          if (!error?.response?.config?.params?._noErrorAlert) {
            const res = error?.response
            if (res?.data) {
              const msg = res?.data?.msg || res?.data?.message
              if (msg) {
                message.error(msg, 6)
              } else {
                message.error(JSON.stringify(error?.response?.data), 6)
              }
            } else {
              message.error(error?.response?.statusText, 6)
            }
          }
        } else if (error?.response?.status === 500) {
          if (!error?.response?.config?.params?._noErrorAlert) {
            message.error(
              typeof error?.response?.data === 'string'
                ? error?.response?.data
                : '内部服务器错误',
              6,
            )
          }
        }
        return Promise.reject(error)
      },
    )
    const costomId = AxiosCostomInstance.interceptors.response.use(
      (response) => {
        if (response.data && response.data.code !== errcodes.FETCH_SUCCESS) {
          if (response.data.code === errcodes.AUTH_FAILED) {
            goLogin()
          } else if (!response.config?.params?._noErrorAlert) {
            message.error(response.data.message, 6)
          }
        }
        //之前返回response.data,如果直接进入该接口对应的页面，初始数据和后续数据结构是不一样的
        return response
      },
      (error: AxiosError) => {
        message.error('系统繁忙，请稍后再试', 6)
        return Promise.reject(error)
      },
    )
    return () => {
      AxiosInitialInstance.interceptors.response.eject(initialId)
      AxiosCostomInstance.interceptors.response.eject(costomId)
    }
  })

  useEffect(() => {
    if (window.location.pathname === '/') {
      if (token) {
        history.replace('/dashboard')
      } else {
        history.replace('/auth')
      }
    }
    Axios.defaults.headers['Authorization'] = `Bearer ${token}`
    setIsGettingAccountinfo(true)
    getAccountInfo()
      .catch()
      .then(() => {
        getPayInfo()
        getWithdrawParams()
        setIsGettingAccountinfo(false)
      })
  }, [history, dispatch, token])

  return (
    <Switch>
      <Route path="/mobile">
        <Mobile></Mobile>
      </Route>
      <Route path="/">
        {!isGettingAccountinfo && (
          <>
            <Switch>
              <Container>
                <Suspense fallback={null}>
                  <Route path="/device">
                    <Switch>
                      <Route path="/device/list" exact>
                        <DeviceList />
                      </Route>
                    </Switch>
                  </Route>
                  <Route path="/deploy">
                    <Deploy></Deploy>
                  </Route>
                  <Route path="/user">
                    <PCenter></PCenter>
                  </Route>
                  <Route path="/help/bind_device" exact>
                    <BindDevice />
                  </Route>
                  <Route path="/dashboard" exact>
                    <Redirect to={{ pathname: '/dashboard/bill' }} />
                  </Route>
                  <Route path="/dashboard/tech" exact>
                    <TechDashboard />
                  </Route>
                  <Route path="/dashboard/bill" exact>
                    <BillDashboard />
                  </Route>
                  <Route path="/wallet" exact>
                    <Wallet />
                  </Route>
                  <Route path="/miner/list" exact>
                    <MinerList />
                  </Route>
                  <Route path="/openplatform">
                    <Switch>
                      <Route path="/openplatform/auth" exact>
                        <OpenplatformAuth />
                      </Route>
                      <Route path="/openplatform/callback" exact>
                        <OpenplatformRedirectUri />
                      </Route>
                    </Switch>
                  </Route>
                  <Route path="/self-tickets" exact>
                    <SelfTickets />
                  </Route>
                  <Route exact path="/dial/list">
                    <DialList />
                  </Route>
                  <Route exact path="/dial/config">
                    <DialConfig />
                  </Route>
                  <Route path="/scan-device" exact>
                    <ScanDevice />
                  </Route>
                </Suspense>
              </Container>
            </Switch>
          </>
        )}
      </Route>
    </Switch>
  )
}

export default App
