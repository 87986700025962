import moment, { Moment } from 'moment'
import { DatePicker } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Button } from 'antd'
import { useAsyncFn } from 'react-use'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { exportMonthlyBillDetail, getMonthlyBillDetail } from '@/utils/api'
import { commonErrorHandler } from '@/utils/chore'
import { AppState } from '@/redux'
import NoticeModal from '@/components/NoticeModal'
import { $ERROR_COLOR, $Link_COLOR, Color } from '@/constants/styles'
import {
  getBillTimeRange,
  getWithdrawalTimeRange,
} from '@/utils/checkWithdrawParams'
import { WithdrawItem } from '@/redux/withdraw'
import { Title } from '../..'
const LinkButton = styled(Button).attrs(() => ({ type: 'text' }))`
  color: ${Color.Primary};
`

const PanelBox = styled.div`
  background: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.02);
  border-radius: 5px;
  padding: 24px;
`

const TableHeaderWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

const BillingStatusLabel = styled.p`
  flex: 1;
  text-align: right;
  font-size: 16px;
  color: ${Color.Error};
`

const TotalIncomeWrapper = styled.p`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
`

const TotalIncomeLabel = styled.span`
  font-size: 14px;
  color: ${Color.SubText};
  line-height: 1;
`

const ErrSpan = styled.span`
  line-height: 1;
  color: ${Color.Error};
`

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  border: 1px solid ${Color.Border};
  th,
  td {
    border: 1px solid ${Color.Border};
    text-align: center;
    line-height: 28px;
    font-size: 12px;
  }
  th {
    background-color: ${Color.Background};
    color: ${Color.SubText};
  }
  td {
    font-weight: 500;
    color: #000000;
  }
  td:not(:last-of-type) {
    border-bottom: 0;
  }
`

const ConfirmButtonWrapper = styled.div`
  margin-top: 22px;
  display: flex;
  justify-content: flex-end;
  .note {
    display: inline-flex;
    flex-direction: column;
    margin-right: 15px;
    align-items: flex-end;
  }
`

const LinkButtonA = styled(Link).attrs(() => ({ target: '_blank' }))`
  padding: 5px 16px;
  color: #2e5bff;
  font-size: 14px;
`

const lastMonth = moment().subtract(1, 'month')

const disabledDate = (date: Moment) =>
  date.isBefore('2020-07') || date.isAfter(moment().subtract(0, 'month'))

const initialBillDetail: PromiseReturnType<typeof getMonthlyBillDetail> = {
  state: '待核验',
  income: [],
  fine: [],
  compensation: [],
  tax_ratio: '0%',
  total_after_tax: 0,
}

export default function () {
  const { role, payInfo } = useSelector((state: AppState) => state.user)
  const withdrawParams = useSelector(
    (state: { withdrawParams: WithdrawItem[] }) => state.withdrawParams,
  )
  const isMiner = role !== 'agent'

  const history = useHistory()
  const [date, setDate] = useState(lastMonth)
  const dateStr = date.format('YYYY-MM')
  const [confirmedModalStatus, setModal] = useState(false)
  const [billDetailState, fetchMonthlyBillDetail] = useAsyncFn(
    (date: string) => {
      return getMonthlyBillDetail({ date })
    },
    [],
  )
  useEffect(() => {
    fetchMonthlyBillDetail(date.format('YYYY-MM'))
  }, [date, fetchMonthlyBillDetail])

  const {
    total_after_tax,
    billDetail,
    incomeHeadRowSpan,
    operatorHeadRowSpan,
    fineHeadRowSpan,
    compensationHeadRowSpan,
  } = useMemo(() => {
    const billDetail = billDetailState.value ?? initialBillDetail
    return {
      billDetail,
      tax_ratio: billDetail.tax_ratio,
      total_after_tax: billDetail.total_after_tax,
      incomeHeadRowSpan: billDetail.income.length + 1,
      operatorHeadRowSpan: billDetail.income.length,
      fineHeadRowSpan: billDetail.fine.length + 1,
      compensationHeadRowSpan: billDetail.compensation.length + 1,
    }
  }, [billDetailState])
  const handleExport = () => {
    exportMonthlyBillDetail({ date: dateStr }, isMiner).catch(
      commonErrorHandler,
    )
  }
  return (
    <div>
      <Title>
        <div className="desc">月账单详情</div>
      </Title>
      <PanelBox>
        <DatePicker
          picker="month"
          allowClear={false}
          value={date}
          disabledDate={disabledDate}
          style={{ marginBottom: 18 }}
          onChange={(date) => {
            setDate(date!)
          }}
        />
        {billDetail.state === '待核验' ? (
          <p>财务核验账单中，核验完成后您会收到短信通知，请及时查看账单哦！</p>
        ) : (
          <>
            <TableHeaderWrapper>
              <TotalIncomeWrapper>
                <TotalIncomeLabel style={{ marginBottom: '10px' }}>
                  最终到账金额（美元）：
                  <ErrSpan style={{ fontSize: '20px' }}>
                    {total_after_tax}
                  </ErrSpan>
                </TotalIncomeLabel>
                <TotalIncomeLabel>
                  （根据<ErrSpan>本月收款信息</ErrSpan>、
                  <ErrSpan>发票类型扣税</ErrSpan>
                  ，最终到账金额以本月正式结算时的收款信息为准）
                </TotalIncomeLabel>
              </TotalIncomeWrapper>
              <BillingStatusLabel>
                <span>账单状态：{billDetail.state}</span>
              </BillingStatusLabel>
            </TableHeaderWrapper>
            <StyledTable>
              <tbody>
                <tr>
                  <th
                    rowSpan={incomeHeadRowSpan}
                    style={{ backgroundColor: 'transparent', minWidth: '3em' }}
                  >
                    收益
                  </th>
                  <th>资源类型</th>
                  <th>计费周期</th>
                  <th>单价（美元）</th>
                  <th>收益（美元）</th>
                  <th>操作</th>
                </tr>
                {billDetail.income.map(
                  (
                    { external_name, start_time, end_time, price, amount },
                    index,
                  ) => (
                    <tr key={index}>
                      <td>{external_name}</td>
                      <td>
                        {start_time}-{end_time}
                      </td>
                      <td>{price}</td>
                      <td>{amount}</td>
                      {index === 0 && (
                        <td rowSpan={operatorHeadRowSpan}>
                          <LinkButtonA to="/device/list">详单</LinkButtonA>
                          <LinkButton onClick={handleExport}>
                            导出详单
                          </LinkButton>
                        </td>
                      )}
                    </tr>
                  ),
                )}
                {billDetail.fine.length ? (
                  <tr>
                    <th
                      rowSpan={fineHeadRowSpan}
                      style={{ backgroundColor: 'transparent' }}
                    >
                      扣除
                    </th>
                    <th colSpan={2}>扣除原因</th>
                    <th colSpan={3}>扣除金额（美元）</th>
                  </tr>
                ) : undefined}
                {billDetail.fine.map(({ id, reason, amount }) => (
                  <tr key={id}>
                    <td colSpan={2}>{reason}</td>
                    <td colSpan={3}>{amount}</td>
                  </tr>
                ))}
                {billDetail.compensation.length ? (
                  <tr>
                    <th
                      rowSpan={compensationHeadRowSpan}
                      style={{ backgroundColor: 'transparent' }}
                    >
                      补偿
                    </th>
                    <th colSpan={2}>补偿原因</th>
                    <th colSpan={3}>补偿金额（美元）</th>
                  </tr>
                ) : undefined}
                {billDetail.compensation.map(({ id, reason, amount }) => (
                  <tr key={id}>
                    <td colSpan={2}>{reason}</td>
                    <td colSpan={3}>{amount}</td>
                  </tr>
                ))}
              </tbody>
            </StyledTable>
            {!billDetailState.loading && (
              <ConfirmButtonWrapper>
                <div className="note">
                  <div style={{ color: $ERROR_COLOR }}>
                    请确认您的收款信息及税票哦
                  </div>
                </div>
              </ConfirmButtonWrapper>
            )}
          </>
        )}
      </PanelBox>
      <NoticeModal
        title="提示"
        visible={confirmedModalStatus}
        okText="我知道了"
        onOk={() => {
          setModal(false)
        }}
        closable={false}
      >
        <div style={{ textAlign: 'center' }}>
          提交成功！您的收益预计在本月
          <span style={{ color: $ERROR_COLOR }}>
            {
              getWithdrawalTimeRange({
                withdrawParams,
                collectionMethod: payInfo.collectionMethod,
              })[0]
            }
            日前
          </span>
          发到您的钱包，届时请在“
          <span style={{ color: $ERROR_COLOR }}>我的钱包</span>”页面自助提现！
        </div>
      </NoticeModal>
    </div>
  )
}
